<template>
  <v-toolbar class="df-toolbar">
    <df-select-value
      :label="$t('object')"
      :value="detectingStatus"
    ></df-select-value>

    <v-divider vertical class="mx-3"></v-divider>

    <df-address-select class="address-select-menu"></df-address-select>

    <v-spacer></v-spacer>

    <!-- trang.tn comment btn -->
    <!-- <v-btn
      class="no-indent mx-3 px-2"
      color="secondary"
      outlined
      large
      :disabled="!$store.getters['session/shouldEnableSavePreset']"
      @click="onSavePresetClicked"
    >
      {{ $t("save_preset") }}
    </v-btn> -->

    <v-item-group
      v-if="$route.name !== 'instant-search'"
      active-class="secondary"
      class="segments d-flex flex-row secondary-group-color"
      mandatory
      v-model="segmentIndex"
    >
      <v-item v-slot:default="{ active, toggle }">
        <div
          class="d-flex align-center px-2"
          :class="active ? `secondary white--text` : `white greytext--text`"
          @click="toggle"
        >
          <v-icon left :color="active ? `white` : `secondary`">mdi-map</v-icon>
          {{ $t("map") }}
        </div>
      </v-item>

      <v-item v-slot:default="{ active, toggle }">
        <div
          class="d-flex align-center px-2"
          :class="active ? `secondary white--text` : `white greytext--text`"
          @click="onChartClick(toggle)"
        >
          <v-icon left :color="active ? `white` : `secondary`">
            mdi-chart-bar
          </v-icon>
          {{ $t("chart") }}
        </div>
      </v-item>
    </v-item-group>
  </v-toolbar>
</template>

<script>
import DfAddressSelect from "@/components/general/DfAddressSelect.vue";
import DfSelectValue from "@/components/general/DfSelectValue.vue";
import EventBus from "../../event-bus";

export default {
  components: {
    DfAddressSelect,
    DfSelectValue,
  },
  props: {
    fixedAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      segmentIndex: 0,
    };
  },
  methods: {
    onSavePresetClicked() {
      this.$store.dispatch("session/savePreset");
    },
    onChartClick(toggleHandler) {
      if (this.isLoggedIn) {
        toggleHandler();
        EventBus.$emit("chart:toggle", true);
      } else {
        this.$store.dispatch(
          "toggleLoginDialog",
          { form: "login" },
          { root: true }
        );
      }
    },
  },
  mounted() {
    EventBus.$off("chart:off");
    EventBus.$on("chart:off", () => {
      this.segmentIndex = 0;
    });
  },
  computed: {
    isLoggedIn() {
      // return this.$store.getters["user/isLoggedIn"];
      return true; //trang.tn edit to remove disc login
    },
    detectingStatus() {
      return this.$store.state.session.session.detected
        ? this.$t("detect_status.detected")
        : this.$t("detect_status.not_detected");
    },
  },
};
</script>

<style scoped lang="scss">
.df-toolbar {
  box-shadow: 0px 2px 3px rgba(48, 48, 48, 0.1);
}
.no-indent {
  text-indent: 0;
  letter-spacing: 0;
}
.segments {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 44px;
  cursor: pointer;
  overflow: hidden;
  font-size: 14px;
}
</style>
