<template>
  <v-menu
    v-model="menu"
    nudge-bottom="24"
    nudge-left="12"
    offset-y
    open-on-click
    :close-on-content-click="false"
    :disabled="disabledMenu"
  >
    <template v-slot:activator="{ on }">
      <v-toolbar-title v-on="on">
        <df-select-value
          :label="$t('area')"
          :value="$store.state.session.session.address"
          v-on="on"
        ></df-select-value>
      </v-toolbar-title>
    </template>
    <v-card width="450">
      <div class="px-4 pt-4 map-photo">
        <v-img :src="require('@/assets/img_draft_1.png')"></v-img>
      </div>

      <v-card-subtitle class="pb-0 mb-0">
        <div class="subtitle-2 font-weight-bold text--text">
          {{ $t("chosen_area") }}
        </div>
      </v-card-subtitle>

      <v-card-title class="pt-0">
        {{ $store.state.session.session.address }}
      </v-card-title>

      <v-list class="pa-0">
        <div v-show="$store.getters['session/sessionType'] === 'detect'">
          <v-divider></v-divider>
          <location-search-menu @onItemClicked="onSearchAddressItemClicked" />
        </div>
        <!-- //trang.tn commented to remove login -->
        <!-- <div v-show="$store.getters['user/isLoggedIn']"> -->
        <div>
          <v-divider></v-divider>
          <df-preset-list />
        </div>

        <div v-show="$store.getters['session/sessionType'] === 'detect'">
          <v-divider></v-divider>
          <v-list-item class="clickable" @click="onDragCustomAreaClicked">
            <v-list-item-title>{{ $t("drag_custom_area") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import bus from "@/event-bus";
import DfSelectValue from "@/components/general/DfSelectValue.vue";
import LocationSearchMenu from "@/components/geolocation/LocationSearchMenu.vue";
import DfPresetList from "@/components/general/DfPresetList.vue";

export default {
  components: {
    DfSelectValue,
    LocationSearchMenu,
    DfPresetList,
  },

  props: {
    disabledMenu: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    bus.$on("menu-selected", () => {
      this.menu = false;
    });
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    onDragCustomAreaClicked() {
      bus.$emit("DetectMonitor:onStartDragCustomMode", true);
      this.menu = false;
    },
    async onSearchAddressItemClicked(item) {
      await this.$store.dispatch("preset/updateCurrentPreset", "");
      await this.$store.dispatch("session/updateDragCustom", false);
      await this.$store.dispatch("session/finishDragCustom", false);

      await this.$store.dispatch("session/updateSessionAddress", {
        location_name: item.text,
        address: item.place_name,
        latitude: item.geometry.coordinates[1],
        longitude: item.geometry.coordinates[0],
        detected: false,
      });

      bus.$emit("DetectMonitor:onStopDragCustomMode");
    },
  },
};
</script>

<style scoped lang="scss">
.v-menu__content {
  box-shadow: 0px 2px 3px rgba(48, 48, 48, 0.1);
}
.clickable {
  cursor: pointer;
}
.map-photo {
  border-radius: 4px;
  overflow: hidden;
}
</style>
