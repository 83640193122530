<template>
  <v-menu
    v-model="menu"
    nudge-right="5"
    offset-x
    open-on-click
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-list-item class="clickable" v-on="on">
        <v-list-item-title>{{ $t("search_address") }}</v-list-item-title>

        <v-list-item-action>
          <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card width="500" class="location-search-card">
      <v-card-text class="pa-0">
        <v-text-field
          class="search-input custom-input-padding"
          v-model="keywords"
          prepend-inner-icon="mdi-magnify-plus-outline"
          placeholder="Start typing to Search"
          clearable
        ></v-text-field>

        <v-list
          two-line
          dense
          align-center
          justify-center
          class="outline location-search-list-wrapper"
        >
          <perfect-scrollbar>
            <div class="results-wrapper location-search-list">
              <v-list-item-group>
                <v-list-item
                  dense
                  v-for="item in items"
                  :key="item.id"
                  :value="item"
                  @click="chooseArea(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.place_name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </perfect-scrollbar>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import bus from "@/event-bus";
import { debounce } from "lodash";

import {
  INSTANT_SEARCH_DEMO_DATA,
  DETECT_MONITOR_DEMO_DATA
} from "../../../data/DemoData";
import { SessionTypes } from "../../store/session";

export default {
  data() {
    return {
      menu: false,
      items: null,
      isLoading: false,
      keywords: null,
      selectedLocation: null
    };
  },

  mounted() {
    bus.$on("menu-selected", () => {
      this.menu = false;
    });
  },

  methods: {
    searchLocation(keywords) {
      if (keywords && keywords.length) {
        const queryOptions = { query: keywords, limit: 10 };
        const type = this.$store.state.session.session.session_type;

        this.isLoading = true;

        this.$mapbox.geocoding
          .forwardGeocode(queryOptions)
          .send()
          .then(response => {
            //TODO: demo purpose, suggested area at top
            if (type === SessionTypes.search) {
              this.items = [
                INSTANT_SEARCH_DEMO_DATA,
                ...response.body.features
              ];
            } else {
              this.items = [
                DETECT_MONITOR_DEMO_DATA,
                ...response.body.features
              ];
            }
            this.isLoading = false;
          });
      }
    },

    chooseArea(item) {
      bus.$emit("menu-selected");
      this.$emit("onItemClicked", item);
    }
  },

  watch: {
    keywords: debounce(function(val) {
      this.searchLocation(val);
    }, 250)
  }
};
</script>

<style scoped lang="scss">
.v-menu__content {
  box-shadow: 0px 2px 3px rgba(48, 48, 48, 0.1);
}
.clickable {
  cursor: pointer;
}
.v-text-field .v-input__slot {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.search-input {
  height: 40px;
}
.location-search-card {
  margin-top: -5px;
}
.location-search-list-wrapper {
  margin-top: 5px;
}
.location-search-list {
  min-height: 60px;
  max-height: 320px;
}
</style>
