<template>
  <v-menu
    v-model="menu"
    nudge-right="5"
    offset-x
    open-on-click
    :close-on-content-click="false"
  >
    <!-- trang.tn commented to remove login -->
    <!-- <template v-slot:activator="{ on }">
      <v-list-item class="clickable" v-on="on" @click="onMenuClick">
        <v-list-item-title>{{ $t("saved_presets") }}</v-list-item-title>

        <v-list-item-action>
          <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template> -->

    <v-card width="450">
      <v-card-title class="pa-1 pl-4 custom-card-title">
        <v-flex>
          <h6>
            {{ $t("saved_presets") }}
          </h6>
        </v-flex>
        <v-flex class="text-md-right">
          <v-btn
            dense
            x-small
            text
            depressed
            :disabled="!hasPrevious"
            color="darken-1"
            @click="loadData('previous')"
          >
            <v-icon left x-small>mdi-arrow-left</v-icon>
            {{ $t("buttons.previous") }}
          </v-btn>
          <v-btn
            dense
            x-small
            text
            depressed
            :disabled="!hasNext"
            @click="loadData('next')"
            class="ml-1 mr-1"
          >
            {{ $t("buttons.next") }}
            <v-icon right x-small>mdi-arrow-right</v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <perfect-scrollbar>
          <v-list one-line dense class="list-items d-flex">
            <v-container
              class="list-loading d-flex align-center justify-center"
              v-if="$store.state.preset.loading"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col cols="6">
                  <v-progress-linear
                    color="secondary"
                    indeterminate
                    rounded
                    height="4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>

            <v-list-item-group color="secondary" v-model="selected" v-else>
              <v-list-item
                v-for="preset in presets"
                :key="preset.id"
                :value="preset.id"
                @click="viewPreset(preset)"
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ preset.location_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small>
                        <strong>
                          {{ $t("session_types." + preset.session_type) }}
                        </strong>
                        {{ $t("created_at") }}
                        {{ preset.created | formatDate }}
                        {{ $t("location") }}
                        ({{ preset.longitude }}, {{ preset.latitude }})
                      </small>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </perfect-scrollbar>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import mapControl from "../../lib/map-controller";
import bus from "@/event-bus";
import { formatDistanceToNow, parseISO } from "date-fns";
import { mapGetters } from "vuex";
export default {
  props: {
    showControls: { type: Boolean, default: false },
  },
  data() {
    return {
      locked: false,
      menu: false,
    };
  },
  mounted() {
    bus.$on("menu-selected", () => {
      this.menu = false;
    });

    this.selectedLocation = this.$store.state.session.session;
  },
  computed: {
    ...mapGetters({
      hasNext: "preset/hasNext",
      hasPrevious: "preset/hasPrevious",
    }),
    selected: {
      get() {
        return this.$store.state.preset.selected;
      },
      set(value) {
        this.$store.dispatch("preset/updateCurrentPreset", value);
      },
    },
    presets: {
      get() {
        return this.$store.state.preset.presets.results;
      },
    },
    lockText() {
      return this.locked ? this.$t("buttons.unlock") : this.$t("buttons.lock");
    },
    lockIcon() {
      return this.locked ? "mdi-lock-open" : "mdi-lock";
    },
  },
  methods: {
    loadData(direction) {
      const session_type = this.$store.state.session.session.session_type;
      this.$store.dispatch("preset/load", { direction, session_type });
    },
    onMenuClick() {
      // if (!this.menu && this.$store.getters["user/isLoggedIn"]) { //trang.tn commented to remove login
      if (!this.menu) {
        this.loadData();
      }
    },
    lockView() {
      mapControl.setZoomEnable(this.$map, this.locked);
      mapControl.setPanEnable(this.$map, this.locked);
      this.locked = !this.locked;
    },
    viewPreset(item) {
      this.selectedLocation.location_name = item.location_name;
      this.selectedLocation.address = item.address;
      this.selectedLocation.latitude = item.latitude;
      this.selectedLocation.longitude = item.longitude;
      //TODO: restore preset state?
      //TODO: confirm before restoring
    },
  },
  filters: {
    formatDate(value) {
      return formatDistanceToNow(parseISO(value), {
        addSuffix: true,
        includeSeconds: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  min-height: 130px;
  max-height: 320px;
}
.list-loading {
  height: 100px;
}
.custom-card-title {
  height: 48px;
}
</style>
