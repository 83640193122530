<template>
  <div class="df-select d-flex flex-row align-center">
    <v-icon left>mdi-menu-down</v-icon>

    <div>
      <div class="caption greytext--text font-weight-bold text-uppercase">
        {{ label }}
      </div>
      <div class="body-2 font-weight-bold pr-12">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: String
  }
};
</script>

<style scoped lang="scss">
.df-select {
  cursor: pointer;
  min-width: 165px;
}
</style>
