export const INSTANT_SEARCH_DEMO_DATA = {
  id: "country.17713921562157440",
  type: "Feature",
  place_type: ["locality"],
  relevance: 1,
  properties: { short_code: "cn", wikidata: "Q148" },
  text: "889 Gangjian Rd, Pudong, Shanghai, China",
  place_name: "889 Gangjian Rd, Pudong, Shanghai, China",
  bbox: [73.499857, 18.060852, 134.77281, 53.560711],
  center: [103, 35],
  geometry: {
    type: "Point",
    // coordinates: [121.69321568325961, 31.373020295509775],
    coordinates: [121.5612, 31.3752],
    suggested_coordinates: [103, 35]
  }
};

export const DETECT_MONITOR_DEMO_DATA = {
  id: "locality.3758079302884781",
  type: "Feature",
  place_type: ["locality"],
  relevance: 1,
  properties: { wikidata: "Q125378" },
  text: "[DEMO] Shanghai Port Main",
  place_name: "[DEMO] Shanghai Port, Shanghai Shi, China",
  bbox: [121.452551, 30.845247, 122.007218, 31.389616],
  center: [121.53972, 31.22306],
  geometry: {
    type: "Point",
    coordinates: [121.64728606449323, 31.331552189987647],
    suggested_coordinates: [121.53972, 31.22306]
  }
};
